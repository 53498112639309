/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FunctionComponent } from 'react';
import { Image } from 'react-bootstrap';
import { Link } from 'gatsby';
import { content, image } from '../komponents/Privacy/Privacy.module.scss';
import privacy from '../../static/images/privacidad-politica.jpg'
import Header from '../komponents/Header/Header';
import Footer from '../komponents/Footer/Footer';

const Privacidad: FunctionComponent = () => (
  <>
    <Header />
    <div className={content}>
      <h1>Privacidad y Cookies</h1>
      <br />
      <Image
        className={image}
        src={privacy}
        alt="Politica de privacidad"
        title="Politica de privacidad"
        rounded
      />

      <h2>Política de cookies</h2>
      <p>
        Utilizamos cookies, balizas web y tecnologías similares (denominadas
        colectivamente "cookies") para mejorar su experiencia con nosotros.
        <br />
        Usamos cookies en nuestros sitios web y en nuestros servicios, así como
        en nuestro portal de clientes, nuestra caja registradora y para mostrar
        opciones de pago. Esta política de cookies explica cómo usamos las
        cookies y su capacidad para elegir cómo se utilizarán.
        <br />
      </p>

      <h2>¿Qué son las cookies?</h2>
      <p>
        Las cookies son pequeños archivos de texto que constan de letras y
        números. Estos se envían desde el servidor web de PuntoTerminal o de
        nuestros socios y es guardado en su dispositivo como se muestra a
        continuación. Usamos las siguientes cookies:
        <br />
        <br />
        Cookies de sesión: cookies temporales que caducan cuando cierra su
        navegador o aplicación.
        <br />
        <br />
        Cookies permanentes: cookies que permanecen en su computadora hasta que
        las borra o caducan.
        <br />
        <br />
        Cookies de origen: cookies establecidas por el sitio web que visita.
        <br />
        <br />
        Cookies de terceros: cookies establecidas por un sitio de terceros.
        <br />
        <br />
        Las balizas web son pequeñas imágenes gráficas transparentes que se
        pueden encontrar en los correos electrónicos que le enviamos. "Técnicas
        similares" son técnicas que almacenan información en su navegador o en
        su dispositivo de una manera similar a las cookies y balizas web.
        <br />
        <br />
        Las cookies permiten que nuestro sitio web y nuestros servicios
        recuerden sus elecciones y preferencias (como inicio de sesión, tamaño
        de texto y otras configuraciones) durante un tiempo, para que no tenga
        que restablecerlas cada vez que nos visite.
      </p>

      <h2>¿Por qué utilizamos cookies?</h2>
      <p>
        Las cookies que utilizamos normalmente mejoran los servicios que le
        ofrecemos. Algunos de nuestros servicios realmente necesitan cookies
        para trabajar, mientras que otros simplemente existen para facilitarle
        nuestros servicios. En general, categorizamos nuestras cookies y su uso
        de la siguiente manera:
        <br />
        <br />
        Las cookies necesarias son absolutamente necesarias para poder ofrecer
        nuestros servicios básicos, por ejemplo para utilizar nuestras opciones
        de pago o nuestro portal de clientes.
        <br />
        <br />
        Nuestros servicios no funcionarían sin estas cookies.
        <br />
        <br />
        Las cookies de optimización nos brindan información analítica completa
        sobre su uso de nuestros servicios. Nos ayudan a mejorar continuamente
        nuestros productos, lo cual es necesario porque nuestros productos no
        serían tan simples sin estas cookies.
        <br />
        <br />
        Las cookies de preferencia nos permiten guardar configuraciones como la
        selección de idioma o si debemos completar previamente su información.
        <br />
        <br />
        Sin este tipo de cookies, no podríamos adaptar nuestros servicios a sus
        deseos. Estas cookies son necesarias porque es fundamental para nuestros
        servicios que su experiencia con nosotros sea lo más sencilla posible.
        <br />
        <br />
        Las cookies relacionadas con la seguridad hacen que nuestros servicios y
        sus datos estén seguros y protegidos, por ejemplo, ayudándonos a
        detectar fraude y proteja sus datos.
        <br />
        <br />
        Como esta es una parte esencial de nuestros servicios, estas cookies
        también son necesarias.
      </p>

      <h2>Cookies de marketing</h2>
      <p>
        <Link to="/">PuntoTerminal.mx</Link>. utiliza cookies de marketing para
        personalizar y mejorar su experiencia en nuestro sitio web, en el correo
        electrónico de marketing y en publicidad de terceros.
        <br />
        <br />
        Marketing automatizado: las cookies de nuestra plataforma para la
        automatización del marketing nos permiten ser más relevantes en nuestra
        comunicación con usted. Basado en datos personales proporcionados por el
        usuario de forma voluntaria, p. Ej. a través de formularios en nuestro
        sitio web, PuntoTerminal utiliza esta información para mejorar la
        capacidad de entregar información más relevante del sitio web y a través
        de canales publicitarios.
        <br />
        <br />
        Análisis: las cookies de análisis recopilan información sobre cómo los
        usuarios interactúan con nuestro sitio web y portal de clientes,lo que
        nos da lecciones sobre los patrones de interacción generales en lugar de
        los comportamientos de los usuarios individuales. Estos datos nos ayudan
        a mejorar su experiencia en el sitio web y en nuestro portal de
        clientes.
        <br />
        <br />
        Publicidad: las cookies publicitarias recopilan información sobre sus
        actividades e intereses que se muestran en nuestro sitio web para que
        podamos mostrar publicidad relevante y dirigida en canales de terceros
        (buscadores, redes sociales y redes publicitarias).
        <br />
        <br />
        Su navegador o dispositivo generalmente le permite cambiar la
        configuración para el uso y alcance de las cookies, para ello debe de ir
        a la configuración de su navegador o dispositivo y obtener información
        sobre cómo ajustar la configuración de cookies. Puede, por ejemplo,
        elegir bloquear todas las cookies, aceptar solo cookies de origen o
        eliminar las cookies cuando cierre su navegador.
        <br />
        <br />
        Tenga en cuenta que algunos de nuestros servicios pueden no funcionar si
        bloquea o elimina las cookies.
      </p>

      <h2>Cookies que usamos</h2>
      <p>
        Es posible que actualicemos esta política en el futuro y, por lo tanto,
        le recomendamos que la revise periódicamente para cumplirla actualizado
        sobre cómo utilizamos las cookies.
        <br />
        <br />
        <h3>¿Cómo puede controlar el uso de cookies de PuntoTerminal?</h3>
        Su navegador o dispositivo generalmente le permite cambiar la
        configuración para el uso y alcance de las cookies, así como para
        cookies que están almacenadas actualmente. Consulte la información de
        referencia de su navegador o dispositivo para comprender cómo ajustarlo.
      </p>
    </div>
    <Footer />
  </>
);

export default Privacidad;

export const Head = () => (
  <>
    <meta charSet="utf-8" />
    <meta name="description" content="Privacidad y Cookies PuntoTerminal" />
    <title>Privacidad y Cookies - PuntoTerminal.mx</title>
  </>
);
